import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, Typography,  TextField, CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { passwordValidator } from '../util/validator';
import { checkPasswordValidity, consoleToLog } from '../util/AppUtil';
import {updatePasswordApi} from '../services/authService';
import { getQueryParams } from '../util/urlUtil';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    innerbg: {
        border: "1px solid #dbdbdb",
        borderRadius: "10px",
        padding: "45px 100px",
        background: "#ffffff",
        [theme.breakpoints.down("md")]: {
            padding: "45px 20px",
        }
    },
    mainbg: {
        background: theme.palette.primary.light
    },
    logo: {
        maxWidth: "200px",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
            maxWidth: "180px",
        }
    },
    title: {
        marginTop: "20px",
        fontSize: "1.1rem"
    }
}));

const UpdatePasswordPage = (props) => {
    //consoleToLog("Theme vikas: ", props);
    const classes = useStyles(props);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const [passwordInValid, setPasswordInValid] = useState(false);
    const [cpasswordInValid, setCPasswordInValid] = useState(false);
    const [queryParams, setQueryParams] = useState(getQueryParams(document.location.search));
    const [loading, setLoading] = useState(false);
    
    const [passwordError, setPasswordError] = useState("");

    useEffect(() => {
        if(queryParams.jwt) {
            navigate(`/update-password`);
        }
    }, [])

    const onSubmitClick = () => {
        /* if (!password) {
            setPasswordInValid(true);
            setPasswordError('* Password Required');
            return;
        } */

        const isPasswordMessage = checkPasswordValidity(password)//passwordValidator(password);
        //const isPasswordValid = passwordValidator(password);
        if (isPasswordMessage) {
            setPasswordInValid(true);
            setPasswordError(isPasswordMessage);
            return;
        }

        if (password !== cpassword) {
            setCPasswordInValid(true);
            return;
        }

        //get url parameter
        let access_token = `Bearer ${queryParams.jwt}`;
        //console.log('query params', queryParams)

        setLoading(true);
        updatePasswordApi(password, access_token)
            .then((response) => {
                const res = response.data;
                consoleToLog("Response updatePasswordApi: ", res);
                setLoading(false);
                enqueueSnackbar(res.message, {
                    variant: 'success'
                });

                navigate(`/login?service=${queryParams.service}`);
            })
            .catch((e) => {
                consoleToLog("updatePasswordApi error", e);
                setLoading(false);
                if (e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {
                        variant: 'error'
                    });
                    return false;
                }
            });
    }

    return (
            <div className={classes.mainbg}>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                    >
                    <Grid container direction="column" xs={10} md={6} lg={5} 
                        className={classes.innerbg}
                    >
                        <img src="/images/eprocessify-logo-svg.svg" 
                            alt="logo" 
                            className={classes.logo} />
                        <Typography variant="body1"
                            className={classes.title}>Update Password</Typography>
                        <Typography variant="subtitle1">Enter new password to access your account</Typography>

                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="password"
                                label="Password"
                                type="password"
                                variant="outlined"
                                margin="dense"
                                error={passwordInValid}
                                helperText={passwordInValid ? passwordError : ''}
                                onChange={e => { 
                                    setPassword(e.target.value);
                                    setPasswordInValid(false);
                                }}
                                InputLabelProps={{style: {fontSize: 14}}}
                            />
                        </Grid>
                        <Grid container item xs={12} style={{marginTop: "15px"}}>
                            <TextField
                                fullWidth
                                id="cpassword"
                                label="Confirm Password"
                                type="password"
                                margin="dense"
                                variant="outlined"
                                onChange={e => { 
                                    setCPassword(e.target.value);
                                    setCPasswordInValid(false);
                                }}
                                error={cpasswordInValid}
                                helperText={cpasswordInValid ? "* Passwords are not matching!"  : ''}
                                InputLabelProps={{style: {fontSize: 14}}}
                                onKeyPress={(ev) => {
                                    //consoleToLog(`Pressed keyCode ${ev.key}`);
                                    if (ev.key === 'Enter') {
                                        onSubmitClick();
                                        ev.preventDefault();
                                    }
                                }}
                            />
                        </Grid>
                        <Button variant="contained" 
                            color="primary" 
                            style={{marginTop: "25px"}}
                            onClick={()=>onSubmitClick()}>
                            {loading && <CircularProgress size={20} style={{marginRight:'8px', color:'#fff'}} />}
                            Update Password
                        </Button>
                    </Grid>
                </Box>
            </div>
    )
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(UpdatePasswordPage);