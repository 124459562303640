//login
export const login = (auth) => ({
    type: 'LOGIN',
    auth
});

//logout
export const logout = () => ({
    type: 'LOGOUT'
});

//update profile details of user
export const updateUser = (user) => ({
    type: 'UPDATE_USER',
    user
});

export const setDeltaTime = (delta) => ({
    type: 'SET_DELTA_TIME',
    delta
});