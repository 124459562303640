import React, {useState, useEffect} from 'react';
import {CircularProgress, Grid} from '@material-ui/core';
import { consoleToLog, decodeAccessToken, getServiceName } from '../util/AppUtil';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {useIsMount} from '../components/useIsMount';
import {logout} from '../actions/auth';
import { makeStyles } from '@material-ui/core/styles';
import { fetchAccessToken } from '../util/AppUtil';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        background: theme.palette.primary.light,
        height:'100vh'
    }
}))

const DashboardPage = (props) => {
    const navigate = useNavigate();
    const isMount = useIsMount();
    const classes = useStyles();

    const [serviceNameValidated, setserviceNameValidated] = useState(true);

    useEffect(() => {
        document.title = 'eProcessify';
        let serviceName = getServiceName(window.location.href);

            if(!serviceName) {
                navigate('/apps', {replace: true});
            } else {

                const fetchToken = async () => {
                    try {
                    const data = await fetchAccessToken(serviceName);
                    if(data) {
                        consoleToLog('Response authenticateApi', data);
                        const {access_token, redirect_url, invalid_service, scope} = data;
                        const user_scope = scope?.join();
                        if(access_token) {
                            if(redirect_url) {
                                window.open(`${redirect_url}\?scope=${user_scope}`, '_self');
                            } else if(!redirect_url && !invalid_service) {
                                navigate(`/not-registered?service=${serviceName}`);
                            } else if(!redirect_url && invalid_service) {
                                navigate('/apps');
                            }
                        } else {
                            navigate(`/login?service=${serviceName}`);
                        }

                    } 
                    } catch (error) {
                        consoleToLog("error fetchAccessToken", error);
                    }
                };
            
                fetchToken();
                
            }
    }, []);

    return (
        <div className={classes.mainbg}>
            {serviceNameValidated ?
                <Grid item container justifyContent='center'>
                    <CircularProgress size={30} style={{marginTop:'30px'}}/>
                </Grid>
                :
                <Grid item container justifyContent='center' alignItems='center'
                    style={{backgroundColor: '#f7f7f7 !important', height:'100%'}}
                    className={classes.bgImage}>
                    <img src='/images/404-not-found.png' />
                </Grid>            
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    isLoading: state.loading.isLoading
});

const mapDispatchToProps = (dispatch) =>({
    logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);