import {  createTheme, responsiveFontSizes } from "@material-ui/core/styles";

let theme = createTheme({
    palette: {
        primary: {
            main: '#3d3d3d',
            light: '#F7F7F7', //light blue for bg
            contrastText: '#fff',
        },
        secondary: {
            main: '#000000',
            light: '#888888', //light gray for subtitle
            contrastText: '#fff',
        }
        },
        typography:{
        button: {
            textTransform: 'none'
        },
        body1: {
            fontSize: 15,
            fontFamily: "inherit"
        },
        body2: {
            fontSize: 14,
            fontWeight: 400
        },
        subtitle1: {
            fontSize: 13,
        },
        subtitle2: {
            
        }
        },

});

theme = responsiveFontSizes(theme);

export default theme;