import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, Link, CircularProgress} from '@material-ui/core';
import { getQueryParams } from '../util/urlUtil';
import { useNavigate } from 'react-router-dom';
import { eprocessifyAppsActivationApi, registerScopeApi, resendActivationApi } from '../services/authService';
import { consoleToLog, getAuthToken } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import {connect} from 'react-redux';
import { login } from '../actions/auth';
import { setLoading } from '../actions/loading';
import {setApiBaseUrl} from '../util/AppUtil';
import { getAccessTokenApi } from '../services/authService';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100%',
        height:'100vh',
        display:'flex',
        direction:'column',
        alignItems:'center'
    },
    itemContainer: {
        padding:'16px 28px', 
        borderRadius:'4px', 
        background:'white', 
        textAlign:'center'
    },
    buttonStyle: {
        marginTop:'16px',
        marginLeft:'40%'
    },
    headingText: {
        fontSize:'36px',
        fontWeight:'bold',
    },
    infoText: {
        fontSize:'22px', 
        marginTop:'8px'
    },
    linkText: {
        fontSize:'16px',
        color:'#007bfb',
        textDecoration:'underline',
        cursor:'pointer',
        marginLeft:"6px"
    },
    loginButton: {
        marginTop:'16px',
        background:'#00bfa6',
        color:'#fff',
        '&:hover' : {
            background:'#008f7d'
        }
    },
    actionText: {
        fontSize:'18px'
    },
    resendLinkText: {
        position:'relative',
        top:"8px",
        fontSize:'16px',
        color:'#007bfb',
        textDecoration:'underline',
        cursor:'pointer',
        marginLeft:"6px",
    }
}))

const ActivationPage = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const {enqueueSnackbar} = useSnackbar();

    const [queryParams, setQueryParams] = useState(getQueryParams(document.location.search));
    const [accountActivated, setAccountActivated] = useState(false);
    const [invalidAuthToken, setInvalidAuthToken] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(queryParams.dont_call_api === "true") {
            navigate('/activate');
            setLoading(false);
        } else {
            if(queryParams.auth_token) {
                getAccessToken();
            } 
        }
    }, []);

    const getAccessToken = () => {
        const env = process.env.REACT_APP_DEBUG ? 'dev' : undefined;
        const access_token = `Bearer ${queryParams.auth_token}`;

        getAccessTokenApi(access_token, env)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response getAccessTokenApi: ', res);
                callActivationApi(access_token);
                navigate('/activate');
            })
            .catch((e) => {
                consoleToLog('Error getAccessTokenApi: ', e.response);
                if(e.response.status === 401) {
                    navigate(`/login?back_url=${window.location.pathname.replaceAll('/', '')}&${window.location.search.replace('?', '')}`)
                }
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const callActivationApi = (accessToken) => {
        const service = queryParams.service;
        const email = queryParams.email;
        const firstname = queryParams.firstname ? queryParams.firstname : '';
        const lastname = queryParams.lastname ? queryParams.lastname : '';
        const country = queryParams.country;

        const apiBaseUrl = setApiBaseUrl(service);

        eprocessifyAppsActivationApi(accessToken, apiBaseUrl, service, email, firstname, lastname, country, queryParams.role, 
            queryParams.invited, queryParams.registered, queryParams.entity_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response eprocessifyAppsActivationApi: ', res);
                enqueueSnackbar(res.message, {variant:'success'});

                registerScope(res.redirect_url);
                
            })
            .catch((e) => {
                consoleToLog('Error eprocessifyAppsActivationApi: ', e);
                setLoading(false);
                if(e.response.status === 401) {
                    navigate(`/login?back_url=${window.location.pathname.replaceAll('/', '')}&${window.location.search.replace('?', '')}`)
                }
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    setInvalidAuthToken(true);
                    return;
                }
            })
    }

    const registerScope = (redirect_url) => {
        registerScopeApi(queryParams.service)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response registerScopeApi: ', res);
                
                    const first_login = res?.first_login;
                    const scope = res.scope.join();
                    window.open(`${redirect_url}&first_login=${res.first_login}&scope=${scope}`, '_self');
                    //window.open(`https://localhost:3000/?first_login=${res.first_login}&scope=${scope}`, '_self');
                    props.setLoading(true);
                    setAccountActivated(res.activated);

            })
            .catch((e) => {
                consoleToLog('Error registerScopeApi: ', e.response);
                setLoading(false);
                if(e.response.status === 401) {
                    navigate(`/login?back_url=${window.location.pathname.replaceAll('/', '')}&${window.location.search.replace('?', '')}`)
                }
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const showActivateAccountMessage = () => {
        return (
                <div>

                    <img src='/images/activation.svg' style={{marginTop:'16px', width:'50%'}}/>

                    <Typography variant='body1' className={classes.headingText}>
                        {displayHeading()}
                    </Typography> 

                {!accountActivated && !invalidAuthToken && 
                    <Typography className={classes.infoText}>
                        An activation email was sent to 
                        <div style={{fontWeight:'600', marginTop:'2px'}}>
                            {queryParams?.email}
                        </div>
                    </Typography>
                }

                    <Typography className={classes.infoText}>
                        {displayMessages()}                    
                    </Typography>
                    
                    <div>
                        {displayActions()}
                    </div>
                </div>
        )
    }


    const showLoginButton = () => {
        return (
                <div>
                    <Button variant='contained'  
                        disableElevation
                        onClick={() => navigate(`/login?service=${queryParams.service}`, {replace: true})}
                        className={classes.loginButton}>
                        Login
                    </Button>
                </div>
        )
    }

    const showResendActivationLink = () => {
        return (
                <div>
                    <Link variant='contained' 
                        color='primary'
                        onClick={onResendActivationClick}
                        className={classes.resendLinkText}>
                        Resend Activation Link
                    </Link>
                </div>
        )
    }

    const showActivateAccountLink = () => {
        return (
                <div style={{display:'flex', alignItems:'start', justifyContent:'center', marginTop:'16px'}}>
                    <Typography className={classes.actionText}>
                        Not received email?
                    </Typography>

                    <Typography
                        onClick={onResendActivationClick}
                        className={classes.linkText}>
                        Resend email
                    </Typography>
                </div>
        )
    }

    
    const displayHeading = () => {
        if(invalidAuthToken) {
            return "Activation Failed";
        } else if(accountActivated) {
            return "Activated Successfully";
        } else {
            return "Activate Account";
        }
    }

    const displayMessages = () => {
        if(invalidAuthToken && queryParams.invited === 'true') {
            return "Please ask your manager or owner to resend invitation!";
        } else if(invalidAuthToken && queryParams.invited === 'false') {
            return "Activation link expired. Please click on resend invitation to get activation email";
        } else if(accountActivated) {
            return "";
        } else {
            return "Please click the link in the email to activate your account";
        }
    }

    const displayActions = () => {
        if(invalidAuthToken && queryParams.invited === 'false') {
            return showResendActivationLink();
        } else if(accountActivated) {
            return showLoginButton();
        } else {
            return showActivateAccountLink();
        }
    }

    const onResendActivationClick = () => {
        resendActivationApi(queryParams.service, queryParams.email)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response resendActivationApi', res);

                enqueueSnackbar(res.message, {variant:'success'});
            })  
            .catch((e) => {
                consoleToLog('Error resendActivationApi', e.response);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    return (
        <div className={classes.mainContainer}>
                {loading ?
                <Grid item container justifyContent='center' style={{marginTop:'32px'}}>
                    <CircularProgress size={25} style={{color:"#212121"}}/>
                </Grid>
                :
                <Grid item container justifyContent='center'>

                    <Grid item sm={6} className={classes.itemContainer}>
                        {
                            showActivateAccountMessage()
                        }
                    </Grid>

                </Grid>}
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth.authObj
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth)),
    setLoading: (isLoading) => dispatch(setLoading(isLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);