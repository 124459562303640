import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import {getServiceName} from '../util/AppUtil';

function PublicRoute({auth }) {
    //const auth = props.auth.access_token;

        let service_name = getServiceName(window.location.href);
        return auth && auth.access_token ? <Navigate to={`/?service=${service_name}`} /> : <Outlet />;
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PublicRoute);