import React from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import {getServiceName} from '../util/AppUtil';

function PrivateRoute({auth}) {
    //const auth = props.auth.access_token;
    let service_name = getServiceName(window.location.href);
    return auth && auth.access_token ? <Outlet/> : <Navigate to={`/login?service=${service_name}`} />;
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);