import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {logout} from '../actions/auth';
import {useNavigate} from 'react-router-dom';
import {logoutApi} from '../services/authService';
import { consoleToLog, getServiceName } from '../util/AppUtil';

const LogoutPage = (props) => {
    const navigate = useNavigate();
    
    useEffect(() => {
        let service_name = getServiceName(window.location.href);
        props.logout();
        navigate(`/login?service=${service_name}`);
        
    }, [])
    
    return (
        <div>
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);