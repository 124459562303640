import { legacy_createStore as createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/auth';
import loadingReducer from '../reducers/loading';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//Store creation:
const storeObj =  (persistedState) => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            loading: loadingReducer
        }),
        persistedState,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default storeObj