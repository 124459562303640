import React, {useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import {connect} from 'react-redux';
import {mainAppSignUpApi, invoiceAppSignUpApi, eprocessifyAppsActivationApi, registerScopeApi} from '../services/authService';
import { consoleToLog, getServiceName } from '../util/AppUtil';
import { useSnackbar } from 'notistack';
import { login } from '../actions/auth';
import { useNavigate } from 'react-router-dom';
import {Grid, Typography, Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {publishUserApi} from '../services/authService';
import { getQueryParams } from '../util/urlUtil';
import {setApiBaseUrl} from '../util/AppUtil';
import {getRedirectUrlApi} from '../services/authService';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100%',
        height:'100vh',
        background: theme.palette.primary.light
    },
    itemContainer: {
        marginTop:'40px', 
        padding:'45px 24px',
        borderRadius:'4px',
        background:'#fff',
        flexBasis:'44%',
        maxWidth:'44%'
    },
    buttonStyle: {
        marginTop:'16px',
        marginLeft:'40%'
    }
}))

const NotRegisteredPage = (props) => {
    const classes = useStyles();
    
    const{enqueueSnackbar} = useSnackbar();
    const navigate = useNavigate();
    const location = useLocation();
    
    const access_token = location.state?.access_token;

    const [loading, setLoading] = useState(false);
    const [serviceName, setServiceName] = useState('');
    const [queryParams, setQueryParams] = useState(getQueryParams(document.location.search));

    useEffect(() => {
        document.title = 'eProcessify';
        setServiceName(queryParams.service);
        //console.log('queryParmas', queryParams);
        // const obj = {
        //     access_token: access_token,
        //     user: userObj
        // }
        // access_token && userObj && props.login(obj)


    }, []);

    

    const onRegisterClick = () => {
        setLoading(true);
        getRedirectUrl();
    }

    const getRedirectUrl = () => {
        getRedirectUrlApi(serviceName)
            .then((response) => {
                const res = response.data
                consoleToLog('Response getRedirectUrlApi', res);

                callActivationApi(res);
            })
            .catch((e) => {
                consoleToLog('Error getRedirectUrlApi', e);
                if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
                    //enqueueSnackbar(e.response.data.detail, { variant: 'error' });
                    navigate(`/login?service=${serviceName}`);
                }
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const callActivationApi = (userObj) => {
        const auth_token = access_token;
        const service = queryParams.service;
        const email = userObj.email;
        const firstname = userObj.firstname ? userObj.firstname : '';
        const lastname = userObj.lastname ? userObj.lastname : '';
        const country = userObj.country;
        const role = userObj.role;
        const invited = userObj.invited;
        const entity_id = userObj.entity_id;

        const apiBaseUrl = setApiBaseUrl(service);

        eprocessifyAppsActivationApi(undefined, apiBaseUrl, serviceName, email, firstname, lastname, country, role, invited, true, entity_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response eprocessifyAppsActivationApi: ', res);
                enqueueSnackbar(res.message, {variant:'success'});

                //setAccountActivated(true);
                registerScope(res.redirect_url);
            })
            .catch((e) => {
                consoleToLog('Error eprocessifyAppsActivationApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                  // setInvalidAuthToken(true);
                    return;
                }
            })
    }

    const registerScope = (redirect_url) => {
        registerScopeApi(queryParams.service)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response registerScopeApi: ', res);
                setLoading(false);

                const scope = res.scope.join();
                window.open(`${redirect_url}&first_login=${res.first_login}&scope=${scope}`, '_self');
            })
            .catch((e) => {
                consoleToLog('Error registerScopeApi: ', e.response);
                setLoading(false);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    return (
        <div className={classes.mainContainer}>
                <Grid item container 
                    justifyContent='center'>

                    <Grid item sm={5} className={classes.itemContainer}>
                        <Typography variant='body1' style={{fontSize:'20px'}}>
                            Your are not registered, Please click on register to create an account
                        </Typography>    
                

                        <Button variant='contained' color='primary'
                            onClick={onRegisterClick}
                            className={classes.buttonStyle}>
                            Register
                        </Button>
                    </Grid>

                </Grid>
            
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
    login: (auth) => dispatch(login(auth))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotRegisteredPage);