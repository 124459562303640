
//import { toast } from 'react-toastify';

const nameValidator = (name) => {
    //"a-z", "A-Z", "." is allowed
    if (/^[A-Za-z\s]{1,}[\.]{0,1}[A-Za-z\s]{0,}$/.test(name)) {
        return true;
    } else {
        return false;
    }
}; 

//organization name validator
const orgNameValidator = (organization_name) => {
    if (organization_name.length >= 1) {//valid
        return true;
    } else { //invalid
        //toast.error(`Organization name is invalid. It should contain atleast one character.`);
        return false;
    }
}; 

const emailValidator = (email) => {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email)) {
        return true;
    } else {
        //toast.error('Email is not valid!');
        return false;
    }
};


const passwordValidator = (password) => {
    const requiredLength = 5;
    if (password.length >= requiredLength) {//valid
        return true;
    } else { //invalid
        //toast.error(`Password is invalid. It should contain atleast ${requiredLength} characters.`);
        return false;
    }
};

const labelNameValidator = (label_name) => {
    if (label_name.length >= 1) {//valid
        return true;
    } else { //invalid
        //toast.error(`Label name is invalid. It should contain atleast one character.`);
        return false;
    }
}

const companyNameValidator = (company_name) => {
    if (!isStringSpaces(company_name)) {
        return true;
    } else {
        //toast.error(`Company name can not be empty!`);
    }
}

const panNoValidator = (pan_no) => {
    const reg = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (reg.test(pan_no)) {
        return true;
    } else {
        //toast.error('PAN is not valid!');
        return false;
    }
}

const gstinValidator = (gstin) => {
    const reg = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
    if (reg.test(gstin)) {
        return true;
    } else {
        //toast.error('GSTIN is not valid!');
        return false;
    }
}
// const addressValidator = (address) => {
//     if (!isStringSpaces(address)) {
//         return true;
//     } else {
//        // toast.error(`Address can not be empty!`);
//     }
// }

const isStringSpaces = (str) => {
    if (str.replace(/\s/g, '').length) {
        return false;
    } else {
        return true;
    }
}

const mobileNoValidator = (number) => {
    const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/im;;
    if(reg.test(number)) {
        return true;
    } else {
        //toast.error('Mobile Number is not valid');
        return false;
    }
}

export {
    nameValidator,
    emailValidator,
    passwordValidator,
    orgNameValidator,
    labelNameValidator,
    companyNameValidator,
    panNoValidator,
    gstinValidator,
// addressValidator,
    isStringSpaces,
    mobileNoValidator
}