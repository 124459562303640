//import ReactGA from 'react-ga';
import country from "../data/country.json";
import state from "../data/state.json";
import {MenuItem} from '@material-ui/core';
import {authenticateApi} from '../services/authService';

export const isDebug = () => {
    return process.env.REACT_APP_DEBUG !== 'false';
};


//input: 'abc' output: 'Abc'
export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}


//input: ' a b ' output: 'ab'
export const removeSpace = (str) => {
    return str.replace(/\s/g, '')
}

//input: 'abc' output: 'Abc'
export const capitalizeFirstLetterOfEachWord = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/\b\w/g, l => l.toUpperCase());
}

export const consoleToLog = (msg, value) => {
    if (isDebug()) console.log(msg, value);
};

export const decodeAccessToken = (redirect_url) => {
    let url = new URL(redirect_url);
    //let value = url.searchParams.get("access_token");
    let value = url.searchParams.get("auth_token");
    let decodeString = (!value) ? '' : window.btoa(value);
    return decodeString;
}

const {countries} = country;
export const showAllCountries = () => (
    countries.map((country)=>{
        return(
            <MenuItem value={country.code}>{country.name}</MenuItem>
        )
    })
)

const {states} = state;
export const showAllStates = () => (
    states.map((state)=>{
        return(
            <MenuItem value={state.code}>{state.name}</MenuItem>
        )
    })
)

// export const trackGAPageView= (page) => {
//     ReactGA.pageview(page);
// }

export const getServiceName = (url_string) => {
    let url = new URL(url_string);
    let value = url.searchParams.get("service");
    return value ? value : '';
}

export const getAuthToken = (url_string) => {
    let url = new URL(url_string);
    let value = url.searchParams.get("auth_token");
    return value ? value : '';
}

const mainAppBaseUrl = process.env.REACT_APP_MAIN_APP_BASE_URL;
const invoiceAppBaseUrl = process.env.REACT_APP_INVOICE_APP_BASE_URL;
const captureAppBaseUrl = process.env.REACT_APP_AP_CAPTURE_BASE_URL;

export const setApiBaseUrl = (service) => {
    switch (service) {
        case 'workplace':
            return mainAppBaseUrl;
    
        case 'invoices':
            return invoiceAppBaseUrl;

        case 'capture':
            return captureAppBaseUrl
    
        default:
            break;
    }
}

export const respectiveAppTitleAndSubtitle = (serviceName) => {
    if(!serviceName) {
        return {
            title: 'Work Management, Automated Data Entry and Invoicing are now Simpler than ever',
            subtitle: 'Invoice generator for small business owners, Automated data entry using AI powered OCR technology, and Email task management for accounting firms'
        }
    } else if(serviceName === 'workplace') {
        return {
            title: 'Unify Work, Emails and Invoices on a single platform for maximum efficiency',
            subtitle: 'Send and Receive email, Automate recurring task creation, Automate email reminders, Automate Invoice generation and make your accounting firm run smoother'
        }
    } else if(serviceName === 'invoices') {
        return {
            title: 'Create professional-looking invoices with greater ease than ever before.',
            subtitle: 'Generate invoices quickly, Track invoices, Automate payment reminders, Stay updated and know when your bill is viewed and downloaded'
        }
    } else if(serviceName === 'capture') {
        return {
            title: 'Purchase Entry in ZOHO Books and Tally is now Simpler than Ever',
            subtitle: 'Save up to 98% on data entry with AI backed OCR technology! Automate text extraction from emails, PDFs, and Images. Save hundreds of hours of manual work.'
        }
    }
}

export const checkPasswordValidity = (value) => {
    const isNonWhiteSpace = /^\S*$/;
    if (!isNonWhiteSpace.test(value)) {
      return "Password must not contain Whitespaces.";
    }
  
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    if (!isContainsUppercase.test(value)) {
      return "Password must have at least one Uppercase Character.";
    }
  
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsLowercase.test(value)) {
      return "Password must have at least one Lowercase Character.";
    }
  
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(value)) {
      return "Password must contain at least one Digit.";
    }
  
    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    if (!isContainsSymbol.test(value)) {
      return "Password must contain at least one Special Symbol.";
    }
  
    const isValidLength = /^.{8,20}$/;
    if (!isValidLength.test(value)) {
      return "Password must be 8-20 Characters Long.";
    }
  
    return null;
  }


export const fetchAccessToken = async (service) => {
    try {
        const response = await authenticateApi(service);
        const data = await response.data;
        return data;
    } catch (error) {
        console.error('Error fetching access token:', error);
        throw error;
    }
};

export const getBackUrl = (url_string) => {
    let url = new URL(url_string);
    let value = url.searchParams.get("back_url");
    return value ? value : '';
}