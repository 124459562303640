import axios from 'axios';
import {getAxios} from './axiosUtil';

export const checkIfUserIsRegisteredApi = (email) => {
    const param = {
        params: {
            email
        }
    }

    return getAxios().get(`${process.env.REACT_APP_AUTH_BASE_URL}/user/is_registered`, param);
}

export const signUpApi = (service, fullname, email, country, password, invited) => {
    let data = {
        service,
        fullname,
        email,
        country,
        password,
        invited
    }

    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/register`, data);
}

export const mainAppSignUpApi = (firstname, lastname, email, country, state, password, id) => {
    let data = {
        firstname,
        lastname,
        email,
        country, 
        state,
        password,
        id
    }
    return getAxios().post(`${process.env.REACT_APP_MAIN_APP_BASE_URL}/signup`, data);
}

export const invoiceAppSignUpApi = (firstname, lastname, email, country, state, password, id) => {
    let data = {
        firstname,
        lastname,
        email,
        country,
        state,
        password, 
        id

    }
    return getAxios().post(`${process.env.REACT_APP_INVOICE_APP_BASE_URL}/user/register`, data);
}

export const signInApi = (email, password, service, env) => {
    let data = {
        email,
        password,
        service,
        env
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/login`, data)
}

export const getRedirectUrlApi = (service) => {
    const param = {
        params: {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_BASE_URL}/user/me`, param);
}

export const publishUserApi = (access_token) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/publish_user`);
}

export const logoutApi = (access_token) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/logout`);
}

export const forgotPasswordApi = (email, service) => {
    let data = {
        email,
        service
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/forgot_password`, data)
}

export const updatePasswordApi = (password, jwt) => {
    let data = {
        password
    }

    getAxios().defaults.headers.common['Authorization'] = jwt;
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/update_password`, data)
}

export const getAllAppsApi = () => {
    return getAxios().get(`${process.env.REACT_APP_AUTH_BASE_URL}/apps`)
}

export const getAllAppsWithAccessTokenApi = (access_token) => {
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().get(`${process.env.REACT_APP_AUTH_BASE_URL}/apps`)
}

export const checkServiceNameApi = (service) => {
    const param = {
        params : {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_BASE_URL}/app/validate`, param)
}

export const eprocessifyAppsActivationApi = (access_token, apiBaseUrl, service, email, firstname, lastname, country, role, invited, registered, entity_id) => {
    let data = {
        service,
        email,
        firstname, 
        lastname,
        country,
        role,
        invited,
        registered,
        entity_id
    }
    if(access_token) {
        getAxios().defaults.headers['Authorization'] = access_token;
    }
    return getAxios().post(`${apiBaseUrl}/user/activation`, data)
}

export const registerScopeApi = (service) => {
    let data = {
        service
    }
    return getAxios().put(`${process.env.REACT_APP_AUTH_BASE_URL}/user/register_scope`, data)
}

export const resendActivationApi = (service, email) => {
    let data = {
        service,
        email
    }
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/resend_activation`, data)
}

export const getCountryApi = (token) => {
    return getAxios().get(` https://ipinfo.io/json?token=${token}`)
}

export const authenticateApi = (service) => {
    const param = {
        params: {
            service
        }
    }
    return getAxios().get(`${process.env.REACT_APP_AUTH_BASE_URL}/authenticate`, param)
}

export const getAccessTokenApi = (access_token, env) => {
    let data = {
        env
    }
    getAxios().defaults.headers.common['Authorization'] = access_token;
    return getAxios().post(`${process.env.REACT_APP_AUTH_BASE_URL}/user/set_token`, data)
}