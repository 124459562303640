import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Grid, Typography, Button, CircularProgress} from '@material-ui/core';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import {getAllAppsApi, eprocessifyAppsActivationApi, registerScopeApi} from '../services/authService';
import { consoleToLog } from '../util/AppUtil';
import { useIsMount } from '../components/useIsMount';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {setLoading} from '../actions/loading';
import { useSnackbar } from 'notistack';
import {setApiBaseUrl} from '../util/AppUtil';
import {getRedirectUrlApi} from '../services/authService';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        width:'100%',
        height:' 100vh'
    },
    workplaceAppStyle: {
        background:'rgba(21, 129, 226, 1)',
        height:' 100vh',
        position: 'relative',
        backgroundImage:'url(/images/workplace-bottom-bg.png)',
        backgroundSize:'100% 100%',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'bottom',
        position: 'fixed',
        display: 'block',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        zIndex: '2',
        cursor: 'pointer',
        //backgroundBlendMode: 'hard-light',
        boxShadow:'inset 0 0 0 2000px rgba(0, 0, 0, 0.3)'
    },
    invoiceAppStyle: {
        background:'#babec8',
        height:' 100vh',
        position: 'relative',
        backgroundImage:'url(/images/invoices-bottom-bg.png)',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'right bottom',
        position: 'fixed',
        display: 'block',
        width: '100%',
        height: '100%',
        top: '0',
        left: '33.33%',
        right: '0',
        bottom: '0',
        zIndex: '2',
        cursor: 'pointer',
        //backgroundColor: 'rgba(0,0,0,0.5)',
        //backgroundBlendMode: 'hard-light',
        boxShadow:'inset 0 0 0 2000px rgba(0, 0, 0, 0.5)'

    },
    captureAppStyle: {
        background:'#f8f8f8',
        height:' 100vh',
        position: 'relative',
        //backgroundImage:'url(/images/invoices-bottom-bg.png)',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'right bottom',
        position: 'fixed',
        display: 'block',
        width: '100%',
        height: '100%',
        top: '0',
        left: '66.66%',
        right: '0',
        bottom: '0',
        zIndex: '2',
        cursor: 'pointer',
        //backgroundColor: 'rgba(0,0,0,0.5)',
        //backgroundBlendMode: 'hard-light',
        boxShadow:'inset 0 0 0 2000px rgba(0, 0, 0, 0.5)'

    },
    itemContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -55%)',
        textAlign:'center',
    },
    iconStyles: {
        color:'white', 
        fontSize:'80px',
        margin:'0px auto'
    },
    iconText: {
        fontSize:'32px',
        color: 'white'
    },
    buttonStyles: {
        background:'#fff',
        marginTop:'16px',
        width:'140px',
        color: '#555',
        '&:hover': {
            background:'#FFFAFA',
        }
    },
    overlayStyle : {
        
    }
}))

const AllAppsPage = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    const [appNameLoading, setAppNameLoading] = useState(undefined);
    
    const [allAppsList, setAllAppsList] = useState([]);

    useEffect(() => {
        document.title = 'eProcessify | Apps';
        callgetAllAppsApi();
    }, [])

    const callgetAllAppsApi = () => {

        setLoading(true);
        getAllAppsApi()
        .then((response) => {
            const res = response.data;
            consoleToLog('Response getAllAppsApi', res);
            setLoading(false);
            //navigate('/apps', {state:{appsObj: res}})
            setAllAppsList(res)
        })
        .catch((e) => {
            consoleToLog('Error getAllAppsApi', e.response);
            setLoading(false);
            if(e.response.data && e.response.data.message) {
                return;
            } 
        })
    }  

    const onOpenOrRegisterClick = (app) => {
        setAppNameLoading(app.name);
        if(!app.is_registered) {
            getRedirectUrl(app);
        } else {
            window.open(`${app.redirect_url}`, '_self')
        }
    }

    const getRedirectUrl = (appObj) => {
        const service = appObj.name;
        getRedirectUrlApi(service)
            .then((response) => {
                const res = response.data
                consoleToLog('Response getRedirectUrlApi', res);

                if(res.email) {
                    setLoading1(1);
                    callActivationApi(res, service);
                } else {
                    navigate(`/register?service=${appObj.name}`)
                }
            })
            .catch((e) => {
                consoleToLog('Error getRedirectUrlApi', e);
                if(e.response && e.response.status === 401 && e.response.data && e.response.data.detail) {
                    //enqueueSnackbar(e.response.data.detail, { variant: 'error' });
                    navigate(`/register?service=${appObj.name}`);
                    setAppNameLoading(undefined);
                }
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const callActivationApi = (resObj, service) => {
        const email = resObj.email;
        const firstname = resObj.firstname ? resObj.firstname : '';
        const lastname = resObj.lastname ? resObj.lastname : '';
        const country = resObj.country;
        const role = resObj.role;
        const entity_id = resObj.entity_id;
        const apiBaseUrl = setApiBaseUrl(service);

        eprocessifyAppsActivationApi(undefined, apiBaseUrl, service, email, firstname, lastname,  country, role, 
            false, true, entity_id) 
            .then((response) => {
                const res = response.data;
                consoleToLog('Response eprocessifyAppsActivationApi: ', res);
                enqueueSnackbar(res.message, {variant:'success'});

                registerScope(res.redirect_url, service);
            })
            .catch((e) => {
                consoleToLog('Error eprocessifyAppsActivationApi: ', e.response);
                setLoading1(false);
                setAppNameLoading(undefined);
                if(e.response.data && e.response.data.message) {
                    enqueueSnackbar(e.response.data.message, {variant:'error'});
                    return;
                }
            })
    }

    const registerScope = (redirect_url, service) => {
        registerScopeApi(service)
            .then((response) => {
                const res = response.data;
                consoleToLog('Response registerScopeApi: ', res);
                setLoading1(false);
                setAppNameLoading(undefined);
                window.open(`${redirect_url}&first_login=${res.first_login}&scope=${res.scope}`, '_self');
                //window.open(`https://localhost:8888/?first_login=${res.first_login}&scope=${res.scope}`, '_self');
            })
            .catch((e) => {
                consoleToLog('Error registerScopeApi: ', e.response);
                setLoading1(false);
                setAppNameLoading(undefined);
                if(e.response.data && e.response.data.message) {
                    return;
                }
            })
    }

    const setClassForAppImage = (service) => {
        switch (service) {
            case 'workplace':
                return classes.workplaceAppStyle;
            
            case 'invoices':
                return classes.invoiceAppStyle;

            case 'capture':
                return classes.captureAppStyle;
            
            default:
                break;
        }
    }

    const setAppName = (service) => {
        switch (service) {
            case 'workplace':
                return 'Workplace';
            
            case 'invoices':
                return 'Invoices';

            case 'capture':
                return 'Capture';
            
            default:
                break;
        }
    }

    return (
        <div className={classes.mainContainer}>
            {loading ?
                <Grid item container justifyContent='center'> 
                    <CircularProgress size={28} 
                        color='primary' 
                        style={{marginTop:'32px'}} />
                </Grid>
                :
                <Grid item container style={{position:'relative'}}>
                    {
                        allAppsList && allAppsList.length > 0 &&
                        allAppsList.map((app) => {
                            return <Grid item
                                        key={app.name}
                                        md={4} 
                                        className={setClassForAppImage(app.name)}>
                                        <Grid item className={classes.itemContainer}>
                                                <img src={app.logo_url} height='45%' width='45%'/>
                                            
                                                <Typography className={classes.iconText}>
                                                    {setAppName(app.name)}
                                                </Typography>
                    
                                                <Button onClick={() => onOpenOrRegisterClick(app)} 
                                                    className={classes.buttonStyles}>
                                                    {app.name === appNameLoading && loading1 && <CircularProgress size={20} style={{marginRight:'4px'}}/>}
                                                    {app.is_registered ? 'Open' : 'Register'}
                                                </Button>
                                        </Grid>
                                    </Grid>
                        })
                    }
                    
                    {/* <Grid item md={6} className={classes.invoiceAppStyle}>
                        <Grid item className={classes.itemContainer}>
                            <DescriptionOutlinedIcon className={classes.iconStyles} />

                            <Typography className={classes.iconText}>
                                Invoice App
                            </Typography>

                            <Button className={classes.buttonStyles}>
                                Register
                            </Button>
                        </Grid>
                    </Grid> */}
                </Grid>
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    isLoading: state.loading.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    setLoading: (isLoading) => dispatch(setLoading(isLoading))
})

export default connect(mapStateToProps, mapDispatchToProps)(AllAppsPage);