import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LoginPage from '../pages/LoginPage';
import { connect } from 'react-redux';
import DashboardPage from '../pages/DashboardPage';
import PublicRoute from './PublicRoute';
import SignUpPage from '../pages/SignUpPage';
import LogoutPage from '../pages/LogoutPage';
import NotRegisteredPage from '../pages/NotRegisteredPage'
import UpdatePasswordPage from '../pages/UpdatePasswordPage';
import AllAppsPage from '../pages/AllAppsPage';
import ActivationPage from '../pages/ActivationPage';

const AppRouter = (props) => {
    return (
        <BrowserRouter>
            <Routes>
            <Route path='/login' element={<LoginPage />} />
            <Route path='/register' element={<SignUpPage />} />
            <Route path="/" element={<DashboardPage/>}/>
            <Route path="/not-registered" element={<NotRegisteredPage/>}/>
            <Route path="/logout" element={<LogoutPage/>}/>
            <Route path="/update-password" element={<UpdatePasswordPage/>} />
            <Route path="/apps" element={<AllAppsPage/>} />
            <Route path="/activate" element={<ActivationPage/>} />
            </Routes>
        </BrowserRouter>
        );
    }

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AppRouter);