import './App.css';
import AppRouter from './routers/AppRouter';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';

function App() {
  return (
      <SnackbarProvider maxSnack={3}
          autoHideDuration={2000}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        TransitionComponent={Slide}>
          <AppRouter />
      </SnackbarProvider>
  );
}

export default App;
